<template>
  <div class="container">
    <div v-if="loading" class="center">
      <p class="loading-text">正在加载中...</p>
    </div>
    <div v-else-if="errorCode === -2" class="center">
      <!-- 微信绑定界面 -->
      <div class="row">
        <input v-model="driverNum" class="input" placeholder="编号" />
      </div>
      <div class="row">
        <input v-model="driverName" class="input" placeholder="姓名" />
      </div>
      <div class="row">
        <button @click="bindWxUser" class="button">绑定</button>
      </div>
    </div>
    <div v-else class="center">
      <div class="row">
        <img v-if="errorCode === 0" src="/success.png" alt="Success" class="icon">
        <img v-else src="/error.png" alt="Error" class="icon">
        <p class="message-text">{{ message }}</p>
        <div v-if="errorCode === 0">
          <div v-if="checkTag">
            <div class="field-row">
              <span class="field-label">验证时间:</span>
              <span class="field-value">{{ signResult?.checkTime }}</span>
            </div>
            <div class="field-row">
              <span class="field-label">验证类型:</span>
              <span class="field-value">{{ signResult?.status == "in" ? "签到" : "签退" }}</span>
            </div>
            <div v-if="signResult?.status == 'in'">
              <div v-if="signResult?.shoudanPlan?.code == 0">
                <div class="field-row">
                  <span class="field-label">场站名称:</span>
                  <span class="field-value">{{ signResult?.shoudanPlan?.stationName }}</span>
                </div>
                <div class="field-row">
                  <span class="field-label">柜组名称:</span>
                  <span class="field-value">{{ signResult?.shoudanPlan?.groupName }}</span>
                </div>
                <div class="field-row">
                  <span class="field-label">柜门编号:</span>
                  <span class="field-value">{{ signResult?.shoudanPlan?.cabinetNum + "-" + (
                    signResult?.shoudanPlan?.doorNum < 10 ? ("0" + signResult?.shoudanPlan?.doorNum) :
                      signResult?.shoudanPlan?.doorNum) }}</span>
                </div>
              </div>
            </div>
            <div class="field-row">
              <span class="field-label">车辆编号:</span>
              <span class="field-value">{{ busInfo?.busNum }}</span>
            </div>
            <div class="field-row">
              <span class="field-label">司机编号:</span>
              <span class="field-value">{{ driverInfo?.userNum }}</span>
            </div>
            <div class="field-row">
              <span class="field-label">司机姓名:</span>
              <span class="field-value">{{ driverInfo?.userName }}</span>
            </div>
            <div class="field-row">
              <span class="field-label">提示信息:</span>
              <span class="field-value">{{ signResult?.shoudanPlan?.errMsg }}</span>
            </div>
          </div>
          <div v-else>
            <div class="field-row">
              <span class="field-label">司机编号:</span>
              <span class="field-value">{{ driverInfo?.userNum }}</span>
            </div>
            <div class="field-row">
              <span class="field-label">司机姓名:</span>
              <span class="field-value">{{ driverInfo?.userName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';

export default {
  data () {
    return {
      loading: true,
      errorCode: null,
      message: '',
      serverId: null,
      busId: null,
      driverNum: '',
      driverName: '',
      openId: '',
      userInfo: null,
      driverInfo: null,
      busInfo: null,
      signResult: null,
      checkTag: true,
    };
  },
  methods: {
    async fetchOAuthCallback () {
      const route = useRoute();
      const { serverId, busId, code, state } = route.query;
      if (serverId && busId) {
        const newUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx04b71f93bc2eb66d&redirect_uri=https%3A%2F%2Fwx.whltcd.com&response_type=code&scope=snsapi_base&state=${serverId}A${busId}#wechat_redirect`;
        window.location.href = newUrl;
      } else if (code && state) {
        this.loading = true;
        this.errorCode = -1;
        try {
          const response = await fetch(`/api/app/iot/wx/oauthCallback?code=${code}&state=${state}`);
          const r = await response.json();
          this.errorCode = r.data.code;
          this.message = r.data.msg;
          this.serverId = r.data.serverId;
          this.busId = r.data.busId;
          this.openId = r.data.openId;
          this.driverInfo = r.data.driverInfo;
          this.busInfo = r.data.busInfo;
          this.signResult = r.data.signResult;
          this.loading = false;
        } catch (error) {
          this.message = '加载失败';
          this.loading = false;
        }
      } else {
        this.message = '再次返回关闭此页';
        this.loading = false;
      }
    },
    async bindWxUser () {
      if (this.driverNum && this.driverName) {
        this.loading = true;
        this.errorCode = -1;
        try {
          const response = await fetch('/api/app/iot/wx/bindWxUser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ serverId: this.serverId, busId: this.busId, driverNum: this.driverNum, driverName: this.driverName, openId: this.openId }),
          });
          const r = await response.json();
          this.message = r.data.msg;
          this.errorCode = r.data.code;
          this.driverInfo = r.data.driverInfo;
          this.busInfo = r.data.busInfo;
          this.checkTag = false;
          this.loading = false;
        } catch (error) {
          this.message = '绑定失败';
          this.loading = false;
        }
      } else {
        alert('请输入编号和姓名');
      }
    },
  },
  mounted () {
    this.fetchOAuthCallback();
  },
};
</script>

<style scoped>
.container {
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 25px;
}

.center {
  text-align: center;
}

.row {
  margin-bottom: 20px;
}

.input {
  padding: 8px;
  width: 280px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 36px;
}

.button {
  padding: 8px 16px;
  width: 300px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 36px;
}

.message-text {
  font-size: 28px;
}

.icon {
  /* 限制图标最大宽度和高度，以适应手机屏幕 */
  max-width: 80px;
  /* 根据需要调整 */
  max-height: 80px;
  /* 根据需要调整 */
  width: auto;
  /* 保持图标原始宽高比 */
  height: auto;
  /* 保持图标原始宽高比 */
}

.field-row {
  display: flex;
  justify-content: flex-start;
  /* 确保标签和值从左边开始 */
  align-items: center;
  border-top: 1px solid #ccc;
  /* 除最后一行外，其他行都有下边框线 */
  font-size: 25px;
  padding: 5px 0;
}

.field-row:last-child {
  border-bottom: 1px solid #ccc;
  /* 移除最后一行的下边框线 */
}

.field-label {
  /* 根据需要设置标签样式 */
  flex-shrink: 0;
  /* 防止标签被挤压 */
  width: 130px;
  /* 假设标签宽度固定，可根据实际情况调整 */
  text-align: left;
  /* 标签右对齐 */
}

.field-value {
  flex-grow: 1;
  /* 值部分填充剩余空间 */
  text-align: left;
  /* 值左对齐 */
  word-wrap: break-word;
  /* 允许长文本换行 */
}
</style>
